import Datepicker from 'flowbite-datepicker/Datepicker';
import srLocale from 'flowbite-datepicker/locales/sr';
import latLocale from 'flowbite-datepicker/locales/sr-latn';
import huLocale from 'flowbite-datepicker/locales/hu';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

Object.assign(Datepicker.locales, srLocale, { lat: latLocale['sr-latn'] }, huLocale);

document.querySelectorAll('[datepicker]').forEach((datepickerElement) => {
  new Datepicker(datepickerElement, {
    format: datepickerElement.dataset.format,
    language: datepickerElement.dataset.locale,
    autohide: true,
    todayHighlight: true,
    weekStart: 1,
    nextArrow: icon(faArrowRight).html[0],
    prevArrow: icon(faArrowLeft).html[0]
  });
});
