import Swiper, { Navigation, Autoplay, Lazy } from 'swiper';

Swiper.use([Navigation, Autoplay]);

const defaultOptions = {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  speed: 700
};

new Swiper('.image-swiper', {
  ...defaultOptions,
  modules: [Lazy],
  preloadImages: false,
  lazy: {
    loadPrevNext: true,
    loadedClass: 'animate-fade-in opacity-0'
  },
  autoplay: {
    delay: 7000
  }
});

new Swiper('.important-informations-swiper', {
  ...defaultOptions,
  autoplay: {
    delay: 5000,
  },
  direction: 'vertical',
});
