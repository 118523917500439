import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './swiper';
import './photo-swipe';
import FormCollection from './form-collection';
import './flowbite-datepicker';

library.add(fas, far, fab);

dom.i2svg();

document.querySelectorAll('[data-dropdown]').forEach(fromElement => {
  const targetElement = document.getElementById(fromElement.dataset.dropdown);

  fromElement.addEventListener('mouseenter', () => {
    const fromElementBoundingClientRect = fromElement.getBoundingClientRect();
    if (
      fromElementBoundingClientRect.x +
      fromElementBoundingClientRect.width +
      targetElement.offsetWidth +
      parseInt(window.getComputedStyle(document.querySelector('main')).paddingRight) >
      document.documentElement.clientWidth
    ) {
      targetElement.classList.add('dropdown-left');
    } else {
      targetElement.classList.add('dropdown-right');
    }
  });

  targetElement.addEventListener('transitionend', () => {
    if (window.getComputedStyle(targetElement).visibility === 'hidden') {
      targetElement.classList.remove('dropdown-left', 'dropdown-right');
    }
  });
});

document.querySelectorAll('[data-collapse]').forEach(element => {
  const targetElement = document.getElementById(element.dataset.collapse);

  let maxHeight = targetElement.firstElementChild.offsetHeight;
  targetElement.querySelectorAll('[data-collapse]').forEach(collapseElement => {
    maxHeight += document.getElementById(collapseElement.dataset.collapse).firstElementChild.offsetHeight;
  });

  if (targetElement.classList.contains('open')) {
    targetElement.style.maxHeight = maxHeight + 'px';
  }

  element.addEventListener('click', () => {
    element.classList.toggle('open');

    if (!targetElement.offsetHeight) {
      targetElement.style.maxHeight = maxHeight + 'px';
    } else {
      targetElement.style.maxHeight = 0;
    }
  });
});

document.querySelectorAll('[data-toggle]').forEach(element => {
  const targetElement = document.getElementById(element.dataset.toggle);

  targetElement.classList.add('toggle');

  element.addEventListener('click', () => {
    targetElement.classList.toggle('show');
  });

  document.addEventListener('click', (event) => {
    if (!targetElement.contains(event.target) && !element.contains(event.target)) {
      targetElement.classList.remove('show');
    }
  });
});

const backToTop = document.querySelector('.back-to-top');

backToTop.addEventListener('click', () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
});

let scrollTimeout;
document.addEventListener('scroll', () => {
  clearTimeout(scrollTimeout);

  scrollTimeout = setTimeout(() => {
    if (window.scrollY < 200) {
      backToTop.classList.remove('show');
    } else {
      backToTop.classList.add('show');
    }
  }, 200);
});

document.querySelectorAll('[data-hide]').forEach(element => {
  const elementToHide = document.getElementById(element.dataset.hide);

  elementToHide.addEventListener('animationend', () => {
    elementToHide.classList.remove('animate-fade-in', 'opacity-0');
    elementToHide.classList.add('opacity-100');
  });

  element.addEventListener('click', () => {
    elementToHide.addEventListener('transitionend', () => {
      if (elementToHide.parentElement.childElementCount < 2) {
        elementToHide.parentElement.remove();
      } else {
        elementToHide.remove();
      }
    });

    elementToHide.classList.add('!opacity-0');
  });
});

new FormCollection(document.querySelector('.form-collection'), dom.i2svg);
