const FormCollection = (() => {
  return class Collection {
    constructor(collectionHolder, i2svg) {
      if (!collectionHolder) return;

      this.i2svg = i2svg;

      const childForms = Array.from(collectionHolder.children);
      const childCnt = childForms.length + 1;

      const addCollectionButton = this.createButtonElement('block ml-auto rounded-tl-2xl rounded-br-2xl bg-vodovod transition-colors hover:bg-vodovod-dark px-3 py-2 text-white', this.createIconElement('fa-plus fa-xl'));
      addCollectionButton.addEventListener('click', () => {
        this.addCollectionForm(collectionHolder, addCollectionButton);
      });

      collectionHolder.appendChild(addCollectionButton);

      childForms.forEach((form) => {
        this.addCollectionFormDeleteLink(form);
      });

      collectionHolder.dataset.index = childCnt;

      if (childCnt < 2) {
        this.addCollectionForm(collectionHolder, addCollectionButton)
      }
    }

    addCollectionForm(collectionHolder, addCollectionButton) {
      const prototype = collectionHolder.dataset.prototype;
      const index = collectionHolder.dataset.index;

      let newForm = prototype;

      newForm = newForm.replace(/__name__label__/g, index);
      newForm = newForm.replace(/__name__/g, index);

      collectionHolder.dataset.index = parseInt(index) + 1;
      const newFormDiv = document.createElement('div');
      newFormDiv.setAttribute('class', 'space-y-3');
      newFormDiv.innerHTML = newForm;

      addCollectionButton.parentNode.insertBefore(newFormDiv, addCollectionButton);

      this.addCollectionFormDeleteLink(newFormDiv);
    }

    addCollectionFormDeleteLink(collectionForm) {
      const removeCollectionButton = this.createButtonElement('block ml-auto rounded-tl-2xl text-red-600 rounded-br-2xl bg-white border border-red-600 transition-colors hover:bg-red-600 hover:text-white px-3 py-2 text-white', this.createIconElement('fa-trash-can fa-lg'));

      collectionForm.prepend(removeCollectionButton);

      removeCollectionButton.addEventListener('click', () => {
        collectionForm.remove();
      });
    }

    createIconElement(iconName = '') {
      const icon = document.createElement('i');
      icon.setAttribute('class', `fa-solid ${iconName}`);

      return icon;
    }

    createButtonElement(classNames = '', icon = null) {
      const button = document.createElement('button');
      button.setAttribute('type', 'button');
      button.setAttribute('class', classNames);

      if (icon) button.appendChild(icon);

      this.i2svg({node: button});

      return button;
    }
  }
})();

export default FormCollection;
