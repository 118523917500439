import PhotoSwipeLightbox from 'photoswipe/lightbox';

const lightbox = new PhotoSwipeLightbox({
  gallery: '.pswp-gallery',
  children: 'a[data-pswp-width]',
  pswpModule: () => import('photoswipe'),
  preload: [0, 0],
  preloadFirstSlide: false
});

lightbox.init();
